.google-button__icon {
    display: inline-block;
    vertical-align: middle;
    margin: 8px 0 8px 8px;
    width: 18px;
    height: 18px;
    box-sizing: border-box;
  }

  .google-button {
    height: 56px;
    background: #EA4335;
    border-radius: 30px;
    display: block;
    border: none;
    margin-top: 20px
  }

.google-button:focus,  .google-button:hover  {
    box-shadow: 1px 4px 5px 1px rgba(0,0,0,0.1);
}

.google-button:active {
    background-color:#ea200d;
    box-shadow: none;
    transition-duration: 10ms;
  }


  @font-face {
    font-family: 'Product Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans'), local('OpenSans'), url(https://fonts.gstatic.com/s/productsans/v5/HYvgU2fE2nRJvZ5JFAumwegdm0LZdjqr5-oayXSOefg.woff2) format('woff2');
  }

  .google-button__text {
    display: inline-block;
    vertical-align: middle;
    padding: 0 20px;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Product Sans';
    color: #fff;

  }
  
  button ~ button {
    margin-left: 20px;
  }